import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Paper,
  InputBase,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
const Sidebar = ({ categories, setData, getArticles }) => {
  const [valueSearch, setValueSearch] = useState('');

  const onSearch = async () => {
    try {
      const res = await axios.get('/api/v1/content_articles/search', {
        params: {
          term: valueSearch,
        },
      });
      console.log(res.data);
      setData(res.data);
    } catch (error) {}
  };

  const onChangeSearch = (event) => {
    setValueSearch(event.target.value);
    if (event.target.value === '') {
      getArticles();
    }
  };
  useEffect(() => {});
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Typography variant="subtitle1" component="span">
                Importante!
              </Typography>
            }
            sx={{
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              padding: '12px',
            }}
          />
          <CardContent>
            <Typography variant="body2">
              Recordemos que es importante como colaborador, el participar en
              las encuestas para la identificación y tratamiento de los riesgos
              psicosociales, los cuales se estarán llevando a cabo esta semana,{' '}
              <Link href="/intranet/035/sesiones" underline="none">
                revisa la siguiente lista{' '}
              </Link>
              para conocer como participar!
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Typography variant="subtitle1" component="span">
                Buscar
              </Typography>
            }
            sx={{
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              padding: '12px',
            }}
          />
          <CardContent>
            <Paper
              // component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                border: '1px solid rgb(0 0 0 / 20%)',
                boxShadow: 'none',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Ingrese el término de búsqueda"
                value={valueSearch}
                onChange={(el) => onChangeSearch(el)}
                // inputProps={{ 'aria-label': 'Ingrese el término de búsqueda' }}
              />
              <IconButton
                onClick={() => onSearch()}
                disabled={valueSearch.length < 4}
                sx={{ p: '10px' }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Typography variant="subtitle1" component="span">
                Categorías
              </Typography>
            }
            sx={{
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              padding: '12px',
            }}
          />
          <CardContent>
            <Grid container spacing={1}>
              {categories.map((el, index) => (
                <Grid xs={6} key={index} item>
                  <Link href={el.link} underline="none" target={el.target}>
                    {el.label}
                  </Link>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Sidebar;
