import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import {
  Card,
  Typography,
  CardContent,
  Grid,
  CardActions,
  Button,
} from '@mui/material';
import ThemeConfig from '../Theme';
import Sidebar from './sidebar';
import axios from 'axios';
import moment from 'moment';

moment.locale('es-mx');

const Articles = ({
  category,
  list_title,
  posts_list,
  categories,
  article,
  articleActive,
}) => {
  const [data, setData] = useState([]);
  const getArticles = async () => {
    try {
      const res = await axios.get('/api/v1/content_articles', {
        params: {
          content_section_code: category?.code || undefined,
        },
      });
      setData(res.data);
    } catch {}
  };

  const getArticleLatest = async () => {
    try {
      const res = await axios.get('/api/v1/content_articles/latest');
      setData(res.data);
    } catch {}
  };
  useEffect(() => {
    if (category?.code) {
      getArticles();
    } else {
      getArticleLatest();
    }
  }, []);

  return (
    <ThemeConfig>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: !articleActive ? '6rem' : '3rem' }}
      >
        <Grid item xs={12} md={8}>
          <Typography variant="h4" component="div" gutterBottom>
            {list_title}
          </Typography>
          <Grid container spacing={2}>
            {!articleActive && (
              <>
                {data.map((el) => (
                  <Grid item xs={12} lg={6} key={el.id}>
                    <Card variant="outlined" sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Publicado el {moment(el.created_at).format('LL')}
                        </Typography>
                        <Typography variant="h6" component="div" gutterBottom>
                          {el.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            display: '-webkit-box !important',
                            '-webkitLineClamp': '3',
                            '-webkitBoxOrient': 'vertical',
                            overflow: 'hidden',
                          }}
                          // noWrap
                        >
                          {ReactHtmlParser(el.body)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          href={`/intranet/articulo/${el.code}`}
                        >
                          Leer mas
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
            {articleActive && (
              <div>
                <Typography variant="h4" component="p">
                  {article.name}
                </Typography>
                <Typography
                  sx={{ fontSize: 14, marginBottom: '20px' }}
                  color="text.secondary"
                  gutterBottom
                  component="p"
                >
                  Publicado el {moment(article.created_at).format('LL')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {ReactHtmlParser(article.body)}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Sidebar
            categories={categories}
            setData={setData}
            getArticles={getArticles}
          />
        </Grid>
      </Grid>
    </ThemeConfig>
  );
};

export default Articles;
