import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const InfoSaveForm = ({ open, setOpen }) => {
  const handleCloseSaveInfo = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen({
      ...open,
      open: false,
    });
  };

  return (
    <Snackbar
      open={open.open}
      autoHideDuration={6000}
      onClose={handleCloseSaveInfo}
    >
      <Alert
        onClose={handleCloseSaveInfo}
        severity={open.type}
        sx={{ width: '100%' }}
      >
        {open.type === 'success'
          ? 'La información se guardo correctamente.'
          : 'Ocurrió un error al, intente nuevamente.'}
      </Alert>
    </Snackbar>
  );
};

export default InfoSaveForm;
