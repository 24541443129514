import React, { useContext, useEffect, useState } from 'react';
import {
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Box,
  Button,
  FormHelperText,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import Context from '../../../brain/context';
import { initialValues } from './dataStorage';
import { useForm, Controller } from 'react-hook-form';

const TEXT_ERROR = 'Campo requerido';
const Guide1 = ({ setOpenSnackbarSaveForm }) => {
  const {
    setStepsForm,
    codeEmployeeCreated,
    setCodeEmployeeCreated,
    sessionCode,
    setLoadingForms,
  } = useContext(Context);

  const [section1, setSection1] = useState([]);
  const [section2, setSection2] = useState([]);
  const [section3, setSection3] = useState([]);
  const [section4, setSection4] = useState([]);
  const [moreForms, setMoreForms] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      ...initialValues,
    },
  });

  const getDataForm = async () => {
    try {
      const res = await axios.get('/api/v1/g_one_questionnaire/g_one_items');
      let data = res.data;
      let section1Data = [];
      let section2Data = [];
      let section3Data = [];
      let section4Data = [];

      data.forEach((el) => {
        if (el.section === 1) {
          section1Data.push(el);
        } else if (el.section === 2) {
          section2Data.push(el);
        } else if (el.section === 3) {
          section3Data.push(el);
        } else if (el.section === 4) {
          section4Data.push(el);
        }
      });
      setSection1(section1Data);
      setSection2(section2Data);
      setSection3(section3Data);
      setSection4(section4Data);
    } catch {}
  };

  useEffect(() => {
    getDataForm();
  }, []);

  const onError = (errors, e) => {
    setOpenSnackbar(true);
  };

  const saveForm = async (values) => {
    try {
      await axios.post('/api/v1/g_one_questionnaire', {
        employee_data: {
          session_code: sessionCode,
          employee_code: codeEmployeeCreated,
        },
        responses: {
          ...values,
        },
      });
      setOpenSnackbarSaveForm({
        type: 'success',
        open: true,
      });
      setLoadingForms(true);
      setStepsForm(3);
      localStorage.setItem('stepForm', '3');
    } catch {
      setOpenSnackbarSaveForm({
        type: 'error',
        open: true,
      });
    }
  };

  const getEmployee = async (employee_number) => {
    try {
      // const res = await axios.post(
      //   `/api/v1/employees/search?employee_number=${employee_number}&session_code=${sessionCode}`
      // );
      // setCodeEmployeeCreated(res.data.employee.code);
      // localStorage.setItem('codeEmployee', res.data.employee.code);
      let codeEmployee = localStorage.getItem('codeEmployee');
      setCodeEmployeeCreated(codeEmployee);
    } catch {
      resetForm();
    }
  };

  const resetForm = () => {
    localStorage.removeItem('statusForm');
    localStorage.removeItem('stepForm');
    localStorage.removeItem('codeEmployee');
    localStorage.removeItem('employeeValues');
    localStorage.removeItem('guide1Values');
    localStorage.removeItem('guide2Values');
    localStorage.removeItem('guide3Values');
    localStorage.removeItem('statusForm');
    location.reload();
  };

  useEffect(() => {
    let employeeValuesStg = localStorage.getItem('employeeValues');
    if (employeeValuesStg) {
      employeeValuesStg = JSON.parse(employeeValuesStg);
      getEmployee(employeeValuesStg.employee_number);
    } else {
      resetForm();
    }
  }, []);
  const onSubmit = (data) => {
    if (moreForms) {
      saveForm(data);
    } else {
      saveForm({
        response_1: 'no',
        response_2: 'no',
        response_3: 'no',
        response_4: 'no',
        response_5: 'no',
        response_6: 'no',
      });
    }
  };

  const watchFields = watch([
    'response_1',
    'response_2',
    'response_3',
    'response_4',
    'response_5',
    'response_6',
  ]);
  useEffect(() => {
    if (watchFields.find((el) => el === 'si')) {
      setMoreForms(true);
    } else {
      setMoreForms(false);
    }
  }, [watchFields]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      localStorage.setItem('guide1Values', JSON.stringify(value))
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const valueStg = localStorage.getItem('guide1Values');
    let value = {};
    if (valueStg) {
      value = JSON.parse(valueStg);
      reset(value);
    }
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div>
      <h3>I.- Acontecimiento traumático severo</h3>
      <p>
        ¿Ha presenciado o sufrido alguna vez, durante o con motivo del trabajo
        un acontecimiento como los siguientes:
      </p>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {section1.map((el) => (
          <div key={el.id}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Controller
              name={`response_${el.order}`}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  error={error?.type === 'required'}
                  name={`response_${el.order}`}
                >
                  <FormLabel>
                    {el.order}.- {el.name}
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby={field.name}
                    name={`response_${el.order}`}
                  >
                    <FormControlLabel
                      value="si"
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {error?.type === 'required' ? TEXT_ERROR : ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
        ))}
        {moreForms && (
          <>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <h3>
              II.- Recuerdos persistentes sobre el acontecimiento (durante el
              último mes):
            </h3>
            {section2.map((el) => (
              <div key={el.id}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Controller
                  name={`response_${el.order}`}
                  control={control}
                  rules={{ required: moreForms }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      error={error?.type === 'required'}
                      name={`response_${el.order}`}
                    >
                      <FormLabel>
                        {el.order}.- {el.name}
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby={field.name}
                        name={`response_${el.order}`}
                      >
                        <FormControlLabel
                          value="si"
                          control={<Radio />}
                          label="Si"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {error?.type === 'required' ? TEXT_ERROR : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            ))}
            <Divider sx={{ mt: 2, mb: 2 }} />
            <h3>
              III.- Esfuerzo por evitar circunstancias parecidas o asociadas al
              acontecimiento (durante el último mes)
            </h3>
            {section3.map((el) => (
              <div key={el.id}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Controller
                  name={`response_${el.order}`}
                  control={control}
                  rules={{ required: moreForms }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      error={error?.type === 'required'}
                      name={`response_${el.order}`}
                    >
                      <FormLabel>
                        {el.order}.- {el.name}
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby={field.name}
                        name={`response_${el.order}`}
                      >
                        <FormControlLabel
                          value="si"
                          control={<Radio />}
                          label="Si"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {error?.type === 'required' ? TEXT_ERROR : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            ))}
            <Divider sx={{ mt: 2, mb: 2 }} />
            <h3>IV.- Afectación (durante el último mes)</h3>
            {section4.map((el) => (
              <div key={el.id}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Controller
                  name={`response_${el.order}`}
                  control={control}
                  rules={{ required: moreForms }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      error={error?.type === 'required'}
                      name={`response_${el.order}`}
                    >
                      <FormLabel>
                        {el.order}.- {el.name}
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby={field.name}
                        name={`response_${el.order}`}
                      >
                        <FormControlLabel
                          value="si"
                          control={<Radio />}
                          label="Si"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {error?.type === 'required' ? TEXT_ERROR : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            ))}
          </>
        )}

        <Box sx={{ marginTop: '30px' }}>
          <Button variant="contained" size="large" fullWidth type="submit">
            Continuar
          </Button>
        </Box>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Falta llenar un campo en el formulario
        </Alert>
      </Snackbar>
    </div>
  );
};;;

export default Guide1;
