import React, { useContext, useState, useEffect } from 'react';
import Start from './Start';
import Context from '../brain/context';
import MainForm from './Form/main';
import End from './End';
import { Card, CardContent } from '@mui/material';
import { Container, Img, Content } from './styles';
import axios from 'axios';

const Main = () => {
  const { statusQuestionnaire } = useContext(Context);
  const [themeData, setThemeData] = useState({});
  const getConfig = async () => {
    try {
      const res = await axios.get('/api/v1/intranet_options');
      let data = {};
      res.data.forEach((el) => {
        data[el.name] = {
          ...el,
        };
      });
      setThemeData(data);
    } catch {}
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <Content
      height={+(statusQuestionnaire === 'end' || statusQuestionnaire === '')}
    >
      <Container>
        {themeData?.['intranet.logo']?.file?.url && (
          <Img
            src={themeData?.['intranet.logo']?.file?.url || ''}
            alt={'logo-entory'}
          />
        )}
        <Card sx={{ borderRadius: '10px', padding: '40px', width: '100%' }}>
          <CardContent>
            {statusQuestionnaire === '' && <Start />}
            {statusQuestionnaire === 'start' && <MainForm />}
            {statusQuestionnaire === 'end' && <End />}
          </CardContent>
        </Card>
      </Container>
    </Content>
  );
};

export default Main;
