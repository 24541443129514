export const initialValues = {
  response_1: '',
  response_2: '',
  response_3: '',
  response_4: '',
  response_5: '',
  response_6: '',
  response_7: '',
  response_8: '',
  response_9: '',
  response_10: '',
  response_11: '',
  response_12: '',
  response_13: '',
  response_14: '',
  response_15: '',
  response_16: '',
  response_17: '',
  response_18: '',
  response_19: '',
  response_20: '',
};
