import React, { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import GlobalStyle from './styles';


const ThemeConfig = ({ children }) => {
  const [themeData, setThemeData] = useState({});
  const getConfig = async () => {
    try {
      const res = await axios.get('/api/v1/intranet_options');
      let data = {};
      res.data.forEach((el) => {
        data[el.name] = {
          ...el,
        };
      });
      setThemeData(data);
    } catch {}
  };

  useEffect(() => {
    getConfig();
  }, []);

  const Theme = createTheme({
    palette: {
      primary: {
        main: themeData?.['intranet.primary_color']?.short_value || '#c73d8d',
        contrastText: '#fff',
      },
      secondary: {
        main: themeData?.['intranet.secondary_color']?.short_value || '#723e90',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily:
        themeData?.['intranet.font']?.short_value || 'Arial, sans-serif',
    },
  });

  return (
    <ThemeProvider theme={Theme}>
      <StyledThemeProvider theme={Theme}>
        <GlobalStyle
          font={
            themeData?.['intranet.font']?.short_value || 'Arial, sans-serif'
          }
        />
        {children}
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

export default ThemeConfig;
