import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: ${(props) => (!props.height ? '100%' : '100vh')};
  background: ${(props) => props?.theme?.palette?.primary?.main};
  background: ${(props) => `linear-gradient(
    to top right,
    ${props?.theme?.palette?.primary?.main} 50%,
    ${props?.theme?.palette?.secondary?.main} 100%
  )`};
`;

export const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 80px 10px 40px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Img = styled.img`
  height: 80px;
  margin-bottom: 20px;
`;
