import React, { useEffect, useState } from 'react';
import {
  Link,
  MenuItem,
  Box,
  MenuList,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ThemeConfig from '../Theme';
import { Img, Container } from './styles';
import axios from 'axios';
import Logo from '../../assets/images/logo-white.png';
import { useMediaQuery } from 'react-responsive';

const Menu = () => {
  const [data, setData] = useState([]);
  const [themeData, setThemeData] = useState({});
  const [drawerStatus, setDrawerStatus] = useState(false);
  const getConfig = async () => {
    try {
      const res = await axios.get('/api/v1/intranet_options');
      let data = {};
      res.data.forEach((el) => {
        data[el.name] = {
          ...el,
        };
      });
      document.title =
        data?.['intranet.intranet_title']?.short_value || 'Intranet';

      let metaDescription = document.createElement('meta');
      metaDescription.setAttribute('property', 'description');
      metaDescription.setAttribute(
        'content',
        data?.['intranet.meta_description']?.long_value || 'Intranet'
      );

      document.getElementsByTagName('head')[0].appendChild(metaDescription);

      let metaRobots = document.createElement('meta');
      metaRobots.setAttribute('property', 'robots');
      metaRobots.setAttribute(
        'content',
        data?.['intranet.robots']?.short_value || 'noindex'
      );

      document.getElementsByTagName('head')[0].appendChild(metaRobots);
      console.log(data);

      let textScript = data?.['intranet.google_tag_code']?.short_value;
      if (textScript) {
        let script = document.createElement('script');
        script.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', ${textScript});`;
        document.head.appendChild(script);
      }

      setThemeData(data);
    } catch {}
  };

  useEffect(() => {
    getConfig();
  }, []);

  const getData = async (data) => {
    try {
      const res = await axios.get('/api/v1/content_sections');
      setData(res.data);
    } catch {
    } finally {
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const DRAWERMENU = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <ThemeConfig>
      <Box
        sx={{
          width: '100%',
          height: 76,
          backgroundColor: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '0',
          position: 'fixed',
        }}
      >
        <Container>
          <div>
            {themeData?.['intranet.logo']?.file?.url && (
              <Link
                href="/intranet"
                sx={{
                  color: 'white',
                  fontSize: '1.25rem',
                }}
                underline="none"
              >
                <Img
                  src={themeData?.['intranet.logo']?.file?.url || ''}
                  alt={'logo-entory'}
                />
              </Link>
            )}
          </div>
          <div>
            {!DRAWERMENU && (
              <MenuList
                sx={{
                  width: '100%',
                  height: 76,
                  backgroundColor: 'primary.main',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Link
                  href={'/intranet'}
                  sx={{
                    color: 'white',
                  }}
                  underline="none"
                >
                  <MenuItem
                    sx={{
                      color: 'white',
                    }}
                  >
                    Inicio
                  </MenuItem>
                </Link>
                {data.map((el) => (
                  <Link
                    href={
                      el.just_link ? el.url : `/intranet/categoria/${el.code}`
                    }
                    sx={{
                      color: 'white',
                    }}
                    target={el.link_target}
                    underline="none"
                    key={el.id}
                  >
                    <MenuItem
                      sx={{
                        color: 'white',
                      }}
                    >
                      {el.name}
                    </MenuItem>
                  </Link>
                ))}
                {/* <MenuItem>My account</MenuItem>
                <MenuItem>Logout</MenuItem> */}
              </MenuList>
            )}
            {DRAWERMENU && (
              <>
                <IconButton
                  aria-label="menu"
                  onClick={() => setDrawerStatus(true)}
                  sx={{
                    color: 'primary.contrastText',
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  // anchor={openDre}
                  open={drawerStatus}
                  onClose={() => setDrawerStatus(false)}
                >
                  <Box
                    role="presentation"
                    onClose={() => setDrawerStatus(false)}
                    onKeyDown={() => setDrawerStatus(false)}
                    sx={{
                      width: 250,
                    }}
                  >
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                      }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton
                          href={'/intranet'}
                          // sx={{
                          //   color: 'white',
                          // }}
                          underline="none"
                        >
                          <ListItemText primary="Inicio" />
                        </ListItemButton>
                      </ListItem>
                      {data.map((el) => (
                        <ListItem key={el.id} disablePadding>
                          <ListItemButton
                            target={el.link_target}
                            href={
                              el.just_link
                                ? el.url
                                : `/intranet/categoria/${el.code}`
                            }
                          >
                            <ListItemText primary={el.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Drawer>
              </>
            )}
          </div>
        </Container>
      </Box>
    </ThemeConfig>
  );
};

export default Menu;
