import React, { Children, createContext, useEffect, useState } from 'react';

const Context = createContext();

export const Provider = ({ children, workCenterId, sessionCode }) => {
  const [statusQuestionnaire, setStatusQuestionnaire] = useState('');
  const [stepsForm, setStepsForm] = useState(0);
  const [codeEmployeeCreated, setCodeEmployeeCreated] = useState('');
  const [loadingForms, setLoadingForms] = useState(true);

  const value = {
    statusQuestionnaire,
    setStatusQuestionnaire,
    stepsForm,
    setStepsForm,
    workCenterId,
    sessionCode,
    codeEmployeeCreated,
    setCodeEmployeeCreated,
    loadingForms,
    setLoadingForms,
  };

  useEffect(() => {
    const statusStorage = localStorage.getItem('statusForm');
    if (statusStorage) {
      setStatusQuestionnaire(statusStorage);
    }
    const stepStorage = localStorage.getItem('stepForm');

    if (stepStorage) {
      setStepsForm(parseInt(stepStorage));
    }

    const codeEmployeeStg = localStorage.getItem('codeEmployee');

    if (codeEmployeeStg) {
      setCodeEmployeeCreated(codeEmployeeStg);
    }
  }, []);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const Consumer = Context.Consumer;
export default Context;
