import styled from 'styled-components';

export const Title = styled.p`
  font-size: 34px;
  color: #525252;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: center;
`;

export const Content = styled.div`
  .MuiLinearProgress-root {
    height: 16px;
    border-radius: 19px;
  }
  .MuiLinearProgress-bar {
    border-radius: 11px;
  }
`;

export const EmployeeInfo = styled.p`
  margin-bottom: 0;
  text-align: right;
  font-size: 15px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
`;