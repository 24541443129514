import React from 'react';
import Typography from '@mui/material/Typography';

const Thanks = () => {
  return (
    <div>
      <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
        Gracias por tu información
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        Nuestro equipo ha recibido tu reporte y procederemos a revisarlo.
      </Typography>
    </div>
  );
};

export default Thanks;
