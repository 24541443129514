import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all.css";
// import 'channels';

// Rails.start();
//Turbolinks.start();
require('jquery')
ActiveStorage.start(); // Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
// $(document).ready(function(){
//   $("#client_name").on("input", function(){
//     var subdomain = $(this).val().toLocaleLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '');
//     $("#client_subdomain").val(subdomain);
//     var subdomain_example = (subdomain == "") ? "suorganizacion" : subdomain;
//     $(".subdomain-demo").text("https://"+subdomain_example+".entory.com.mx")
//   })
  
// });
 
