import React, { useEffect, useState } from 'react';
import { Container, Img, Content } from './styles';
import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  Card,
  CardContent,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ThemeConfig from '../Theme';
import { useForm, Controller } from 'react-hook-form';
import FormGroupHelper from './FormGroupHelper';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import Thanks from './thanks';

const TEXT_ERROR = 'Campo requerido';
const FormComplain = () => {
  const [themeData, setThemeData] = useState({});
  const [formSend, setFormSend] = useState(false);
  const getConfig = async () => {
    try {
      const res = await axios.get('/api/v1/intranet_options');
      let data = {};
      res.data.forEach((el) => {
        data[el.name] = {
          ...el,
        };
      });
      setThemeData(data);
    } catch {}
  };

  useEffect(() => {
    getConfig();
  }, []);

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      entryway: 'intranet',
      work_center_id: '', 
      name: '', 
      entryway: '', 
      policy_known: '', 
      mobbing_to_myself: '', 
      mobbing_to_others: '', 
      bullying_to_myself: '', 
      bullying_to_others: '', 
      has_pressure: '', 
      pressure_type: '', 
      higher_employee_issues: '', 
      middle_employee_issues: '', 
      additional_training: '', 
      work_load: '', 
      people_involved: '', 
      complaint_description: '',
    },
  });

  const [buttonLoading, setButtonLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    type: 'success',
    message: 'message',
    status: false,
  });

  const [workCenters, setWorkCenter] = useState([]);
  const saveForm = async (data) => {
    try {
      await axios.post('/api/v1/complaints', {
        complaint: {
          ...data,
        },
      });
      reset();
      setOpenSnackbar({
        type: 'success',
        message: 'El formulario se envio correctamente',
        status: true,
      });
      setFormSend(true);
    } catch {
      setOpenSnackbar({
        type: 'error',
        message: 'Ocurrió un error al enviar el formulario',
        status: true,
      });
    } finally {
      setButtonLoading(false);
    }
  };

  const onSubmit = (data) => {
    setButtonLoading(true);
    saveForm(data);
  };

  const handleClose = () => {
    setOpenSnackbar({
      ...openSnackbar,
      status: false,
    });
  };

  const getWorkCenterPublic = async () => {
    try {
      const res = await axios.get('/api/v1/work_centers_public');
      setWorkCenter(res.data);
    } catch {
      setOpenSnackbar({
        type: 'error',
        message: 'Ocurrió un error al cargar los centros de trabajo',
        status: true,
      });
    }
  };

  useEffect(() => {
    getWorkCenterPublic();
  }, []);
  return (
    <ThemeConfig>
      <Content height={+formSend}>
        <Container>
          {themeData?.['intranet.logo']?.file?.url && (
            <Img
              src={themeData?.['intranet.logo']?.file?.url || ''}
              alt={'logo-entory'}
            />
          )}
          <Card sx={{ borderRadius: '10px', padding: '40px', width: '100%' }}>
            {!formSend && (
              <CardContent>
                <Typography
                  variant="h4"
                  gutterBottom
                  component="div"
                  align="center"
                >
                  Cuestionario para presentar quejas por prácticas opuestas al
                  entorno organizacional favorable y para denunciar actos de
                  violencia laboral
                </Typography>
                <Divider sx={{ mt: 4, mb: 4 }} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2} columns={16}>
                    <Grid item xs={8}>
                      <Controller
                        name="work_center_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            select
                            id="work_center_id"
                            label="Centro de trabajo"
                            fullWidth
                            error={error?.type === 'required'}
                            helperText={
                              error?.type === 'required' ? TEXT_ERROR : ''
                            }
                          >
                            {workCenters.map((el) => (
                              <MenuItem value={el.id} kye={el.id}>
                                {el.name_with_org}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="¿Cuál es su nombre o número de empleado?"
                            id="name"
                            fullWidth
                            helperText="Deje el campo en blanco para permanecer anónimo"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="policy_known"
                    label="¿Conoce su política para prevenir riesgos psicosociales?"
                  />
                  {/* <Divider sx={{ mt: 2, mb: 2 }} />
                  <Controller
                    name="policy_article"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Conoce el(los) artículo(s) de la política que se está(n) infringiendo?"
                        id="policy_article"
                        fullWidth
                        error={error?.type === 'required'}
                        helperText={
                          error?.type === 'required' ? TEXT_ERROR : ''
                        }
                      />
                    )}
                  /> */}
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="mobbing_to_myself"
                    label="¿Considera que está padeciendo violencia dentro de su centro de
                    trabajo?"
                  />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="mobbing_to_others"
                    label="¿Considera que alguien está viviendo violencia dentro de su
                    trabajo?"
                  />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="bullying_to_myself"
                    label="¿Ha sido victima de intimidación?"
                  />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="bullying_to_others"
                    label="¿Conoce a alguna víctima de intimidación?"
                  />
                  {/* <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="watched_bullying"
                    label="¿Ha visto insultos, humillaciones, burlas, devaluaciones,
                    marginación, indiferencia, comparaciones destructivas, rechazo o
                    amenazas?"
                  /> */}
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="has_pressure"
                    label="¿Le han presionado para realizar o dejar de realizar alguna acción
                    en contra de su voluntad?"
                  />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Controller
                    name="pressure_type"
                    control={control}
                    rules={{ required: false }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        select
                        id="pressure_type"
                        label="¿Cómo ha sido la presión?"
                        fullWidth
                      >
                        <MenuItem value="verbal">Verbal</MenuItem>
                        <MenuItem value="physical">Física</MenuItem>
                      </TextField>
                    )}
                  />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="higher_employee_issues"
                    label="¿Considera que algún superior dificulta su labor en el centro de
                    trabajo?"
                  />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="middle_employee_issues"
                    label="¿Considera que algún compañero dificulta su labor en el centro de
                    trabajo?"
                  />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="additional_training"
                    label="¿Considera que requiere capacitación extra?"
                  />
                  {/* <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="performance_recognition"
                    label="¿Considera que se debe reconocer el desempeño de los trabajadores?"
                  /> */}

                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="work_load"
                    label="¿Considera que las cargas de trabajo no son adecuadas?"
                  />
                  {/* <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="has_ats"
                    label="¿Desea reportar haber sufrido algún acontecimiento traumático
                    severo con motivo de su actividad laboral?"
                  /> */}
                  {/* <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="tasks_definition"
                    label="¿Considera que falta claridad en la definición de su labor (sus
                    actividades)?"
                  /> */}

                  {/* <Divider sx={{ mt: 2, mb: 2 }} />
                  <FormGroupHelper
                    control={control}
                    name="require_evaluation"
                    label=" ¿Considera que requiere apoyo psicológico, médico o psiquiátrico?"
                  /> */}
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Controller
                    name="people_involved"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Personas o colaboradores involucrados"
                        id="people_involved"
                        fullWidth
                        error={error?.type === 'required'}
                        helperText={
                          error?.type === 'required' ? TEXT_ERROR : ''
                        }
                      />
                    )}
                  />

                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Controller
                    name="subject"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Asunto"
                        id="subject"
                        fullWidth
                        error={error?.type === 'required'}
                        helperText={
                          error?.type === 'required' ? TEXT_ERROR : ''
                        }
                      />
                    )}
                  />

                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Controller
                    name="complaint_description"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Con base en sus respuestas anteriores provea una descripción detallada"
                        id="complaint_description"
                        multiline
                        fullWidth
                        rows={5}
                        error={error?.type === 'required'}
                        helperText={
                          error?.type === 'required' ? TEXT_ERROR : ''
                        }
                      />
                    )}
                  />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    size="large"
                    loading={buttonLoading}
                    loadingPosition="start"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    Enviar queja
                  </LoadingButton>
                </form>
                <Snackbar
                  open={openSnackbar.status}
                  autoHideDuration={5000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity={openSnackbar.type}
                    sx={{ width: '100%' }}
                  >
                    {openSnackbar.message}
                  </Alert>
                </Snackbar>
              </CardContent>
            )}
            {formSend && (
              <CardContent>
                <Thanks />
              </CardContent>
            )}
          </Card>
        </Container>
      </Content>
    </ThemeConfig>
  );
};;

export default FormComplain;
