export const initialValues = {
  response_1: '',
  response_2: '',
  response_3: '',
  response_4: '',
  response_5: '',
  response_6: '',
  response_7: '',
  response_8: '',
  response_9: '',
  response_10: '',
  response_11: '',
  response_12: '',
  response_13: '',
  response_14: '',
  response_15: '',
  response_16: '',
  response_17: '',
  response_18: '',
  response_19: '',
  response_20: '',
  response_21: '',
  response_22: '',
  response_23: '',
  response_24: '',
  response_25: '',
  response_26: '',
  response_27: '',
  response_28: '',
  response_29: '',
  response_30: '',
  response_31: '',
  response_32: '',
  response_33: '',
  response_34: '',
  response_35: '',
  response_36: '',
  response_37: '',
  response_38: '',
  response_39: '',
  response_40: '',
  response_41: '',
  response_42: '',
  response_43: '',
  response_44: '',
  response_45: '',
  response_46: '',
  response_47: '',
  response_48: '',
  response_49: '',
  response_50: '',
  response_51: '',
  response_52: '',
  response_53: '',
  response_54: '',
  response_55: '',
  response_56: '',
  response_57: '',
  response_58: '',
  response_59: '',
  response_60: '',
  response_61: '',
  response_62: '',
  response_63: '',
  response_64: '',
  response_65: '',
  response_66: '',
  response_67: '',
  response_68: '',
  response_69: '',
  response_70: '',
  response_71: '',
  response_72: '',
};
