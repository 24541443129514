export const initialValues = {
  response_1: '',
  response_2: '',
  response_3: '',
  response_4: '',
  response_5: '',
  response_6: '',
  response_7: '',
  response_8: '',
  response_9: '',
  response_10: '',
  response_11: '',
  response_12: '',
  response_13: '',
  response_14: '',
  response_15: '',
  response_16: '',
  response_17: '',
  response_18: '',
  response_19: '',
  response_20: '',
  response_21: '',
  response_22: '',
  response_23: '',
  response_24: '',
  response_25: '',
  response_26: '',
  response_27: '',
  response_28: '',
  response_29: '',
  response_30: '',
  response_31: '',
  response_32: '',
  response_33: '',
  response_34: '',
  response_35: '',
  response_36: '',
  response_37: '',
  response_38: '',
  response_39: '',
  response_40: '',
  response_41: '',
  response_42: '',
  response_43: '',
  response_44: '',
  response_45: '',
  response_46: '',
};
