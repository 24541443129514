import styled from 'styled-components';

export const Img = styled.img`
  height: 50px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 576px) {
    min-width: 540px;
  }
  @media (min-width: 768px) {
    min-width: 720px;
  }
  @media (min-width: 992px) {
    min-width: 960px;
  }
  @media (min-width: 1200px) {
    min-width: 1140px;
  }
`;
