import React from 'react';
import { Title, Content, Description } from './styles';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const End = () => {
  return (
    <Content>
      <Title>Formulario terminado</Title>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={100} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            color="text.secondary"
          >{`100%`}</Typography>
        </Box>
      </Box>
      <Description>
        Te agradecemos el esfuerzo para que nuestra empresa esté en
        cumplimiento, que tengas un buen día.
      </Description>
    </Content>
  );
};

export default End;
