import React, { useContext } from 'react';
import { Title, Description, ButtonMui } from './styles';
import Context from '../../brain/context';

const Start = () => {
  const { setStatusQuestionnaire, setStepsForm } = useContext(Context);

  const handleInitQuestionnarie = () => {
    setStatusQuestionnaire('start');
    setStepsForm(1);
    localStorage.setItem('statusForm', 'start');
    localStorage.setItem('stepForm', '1');
  };

  return (
    <div>
      <Title>Importante antes de comenzar</Title>
      <Description>
        La Secretaria del Trabajo y Previsión Social a través de tu centro de
        trabajo invita a dar cumplimiento con la NOM-035-STPS-2018. Para
        lograrlo se requiere que respondas tus cuestionarios en un lugar comodo,
        sin distractores y con adecuada ventilación e iluminación.
      </Description>
      <ButtonMui
        variant="contained"
        size="large"
        color="secondary"
        onClick={handleInitQuestionnarie}
      >
        Confirmo que me encuentro cumpliendo con las condiciones de aplicación
      </ButtonMui>
    </div>
  );
};

export default Start;
