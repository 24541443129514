import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormHelperText,
} from '@mui/material';

const TEXT_ERROR = 'Campo requerido';

const FormGroupHelper = ({ control, label, name }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={error?.type === 'required'} name={name}>
          <FormLabel>{label}</FormLabel>
          <RadioGroup {...field} row aria-labelledby={field.name} name={name}>
            <FormControlLabel value="true" control={<Radio />} label="Si" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText>
            {error?.type === 'required' ? TEXT_ERROR : ''}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormGroupHelper;
