import React from 'react';
import Main from '../UI/main';
import { Provider } from './context';
import ThemeConfig from '../../Theme';

const Index = ({ work_center_id, session_code }) => {

  return (
    <ThemeConfig>
      <Provider workCenterId={work_center_id} sessionCode={session_code}>
        <Main />
      </Provider>
    </ThemeConfig>
  );
};

export default Index;
