import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Divider,
  Grid,
  Button,
  MenuItem,
  Box,
  InputAdornment,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import Context from '../../../brain/context';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, Controller } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';

const TEXT_ERROR = 'Campo requerido';

const Employee = ({ setOpenSnackbarSaveForm }) => {
  const {
    setStepsForm,
    workCenterId,
    sessionCode,
    setCodeEmployeeCreated,
    setLoadingForms,
  } = useContext(Context);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [genders, setGenders] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [educationalLevels, setEducationalLevels] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [departaments, setDepartaments] = useState([]);
  const [jobPositionsType, setJobPositionsType] = useState([]);
  const [contractsType, setContractsType] = useState([]);
  const [staffsType, setStaffsType] = useState([]);
  const [workingHoursType, setWorkingHoursType] = useState([]);
  const [shiftRotation, setShiftRotation] = useState([]);
  const [timeInCurrentPosition, setTimeInCurrentPosition] = useState([]);
  const [timeInCompany, setTimeInCompany] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [timeRanges, setTimesRange] = useState([]);

  const { handleSubmit, control, watch, setValue, reset, getValues } = useForm({
    defaultValues: {
      employee_number: '',
      name: '',
      email: '',
      phone_number: '',
      last_name: '',
      gender: '',
      birthday: '',
      marital_status: '',
      education_level: '',
      job_position: '',
      department: '',
      job_position_type: '',
      contract_type: '',
      staff_type: '',
      working_hours_type: '',
      shift_rotation: '',
      time_in_current_position: '',
      time_in_company: '',
      work_experience: '',
    },
  });

  const getInfoForms = async (varName, setState) => {
    try {
      const res = await axios.get('/api/v1/organization_catalogues', {
        params: {
          name: varName,
          session_code: sessionCode,
        },
      });
      setState(res.data);
    } catch {
    } finally {
    }
  };

  useEffect(() => {

    setTimeout(() => {
        getInfoForms('gender', setGenders);
    }, 250)

    setTimeout(() => {
      getInfoForms('marital_status', setMaritalStatus);
    }, 500)

    setTimeout(() => {
        getInfoForms('education_level', setEducationalLevels);
    }, 1000)

    setTimeout(() => {
        getInfoForms('job_position', setJobs);
    }, 1250)

    setTimeout(() => {
      getInfoForms('department', setDepartaments);
    }, 1750)

    setTimeout(() => {
      getInfoForms('job_position_type', setJobPositionsType);
    }, 2000)

    setTimeout(() => {
      getInfoForms('contract_type', setContractsType);
    }, 2250)

    setTimeout(() => {
      getInfoForms('staff_type', setStaffsType);
    }, 2500)

    setTimeout(() => {
      getInfoForms('working_hours_type', setWorkingHoursType);
    }, 2750)

    setTimeout(() => {
      getInfoForms('shift_rotation', setShiftRotation);
    }, 3000)

    setTimeout(() => {
      getInfoForms('time_ranges', setTimesRange);
    }, 3250)

    setTimeout(() => {
      getInfoForms('work_experience', setWorkExperience);
    }, 3500)
        
    
  }, []);

  const saveForm = async (values) => {
    try {
      const res = await axios.post('/api/v1/employees', {
        employee: {
          work_center_id: workCenterId,
          ...values,
        },
      });
      let codeEmployee = res.data.created.employee.code;
      setOpenSnackbarSaveForm({
        type: 'success',
        open: true,
      });
      setCodeEmployeeCreated(codeEmployee);
      setLoadingForms(true);
      localStorage.setItem('codeEmployee', codeEmployee);
      setStepsForm(2);
      localStorage.setItem('stepForm', '2');
    } catch {
      setOpenSnackbarSaveForm({
        type: 'error',
        open: true,
      });
    }
  };
  const editForm = async (employee, values) => {
    try {
      const res = await axios.put('/api/v1/employees', {
        employee: {
          // work_center_id: workCenterId,
          employee_number: employee.employee_number,
          code: employee.code,
          ...values,
        },
      });

      console.log(res);
      let codeEmployee = res.data.updated.employee.code;
      setOpenSnackbarSaveForm({
        type: 'success',
        open: true,
      });
      setCodeEmployeeCreated(codeEmployee);
      setLoadingForms(true);
      localStorage.setItem('codeEmployee', codeEmployee);
      setStepsForm(2);
      localStorage.setItem('stepForm', '2');
    } catch {
      setOpenSnackbarSaveForm({
        type: 'error',
        open: true,
      });
    }
  };

  const searchEmployee = async (val, type) => {
    try {
      const res = await axios.post(
        `/api/v1/employees/search?employee_number=${val}&session_code=${sessionCode}`
      );
      if (type === 'search') {
        reset(res.data.employee);
      } else {
        return { employee: res.data.employee, exist: true };
      }
    } catch (error) {
      setOpenSnackbar(true);
      return false;
    }
  };

  const onSubmit = async (data) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const employee_number = urlParams.get('employee_number');
    const employee = await searchEmployee(employee_number, ""); 
    localStorage.getItem('codeEmployee', employee);
    console.log(employee);
    if (employee_number && employee?.exist) {
      editForm(employee, data);
    } else {
      saveForm(data);
    }
  };
  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      localStorage.setItem('employeeValues', JSON.stringify(value))
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const valueStg = localStorage.getItem('employeeValues');
    let value = {};
    if (valueStg) {
      value = JSON.parse(valueStg);
      reset(value);
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const employee_number = urlParams.get('employee_number');
    if (employee_number) {
      searchEmployee(employee_number, 'search');
      //alert(employee_number)
    }
  }, []);

  const onChangeBirthday = (el, date) => {
    setValue('birthday', el);
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        timeout = null;
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const handleClickValueEmployee = () => {
    const singleValue = getValues('employee_number');
    searchEmployee(singleValue, 'search');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const onKeyPress = (event) => {
    if (event.which == '13') {
      const singleValue = getValues('employee_number');
      searchEmployee(singleValue, 'search');
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const keyForm = (event) => {
    if (event.which == '13') {
      event.preventDefault();
    }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
        onKeyPress={keyForm}
      >
        {/* <Controller
          name="employee_number"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Número de colaborador"
              id="employee_number"
              fullWidth
              onKeyPress={onKeyPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      startIcon={<SearchIcon />}
                      onClick={handleClickValueEmployee}
                    >
                      Buscar colaborador
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Divider sx={{ mt: 2, mb: 2 }} /> */}
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Nombre*"
                  id="name"
                  fullWidth
                  error={error?.type === 'required'}
                  helperText={error?.type === 'required' ? TEXT_ERROR : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={16} sm={8}>
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Apellidos(s)*"
                  id="last_name"
                  fullWidth
                  error={error?.type === 'required'}
                  helperText={error?.type === 'required' ? TEXT_ERROR : ''}
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Email"
                  id="email"
                  type="email"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={16} sm={8}>
            <Controller
              name="phone_number"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Número móvil a 10 dígitos"
                  id="phone_number"
                  InputProps={{ type: 'number' }}
                  fullWidth
                  error={
                    (field?.value?.length ?? '') > 0
                      ? field?.value?.length === 10
                        ? false
                        : true
                      : false
                  }
                  helperText={
                    (field?.value?.length ?? '') > 0
                      ? field?.value?.length === 10
                        ? ''
                        : 'Es necesario que ingrese los 10 dígitos'
                      : ''
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="gender"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="gender"
                  label="Sexo*"
                  fullWidth
                  error={error?.type === 'required'}
                  helperText={error?.type === 'required' ? TEXT_ERROR : ''}
                >
                  {genders.map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={16} sm={8}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={'es'}
            >
              <Controller
                name="birthday"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label="Fecha de nacimiento*"
                    name="birthday"
                    id="birthday"
                    format="DD/MM/YYYY"
                    onChange={onChangeBirthday}
                    disableFuture
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          error={error?.type === 'required'}
                          helperText={
                            error?.type === 'required' ? TEXT_ERROR : ''
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="marital_status"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="marital_status"
                  label="Estado civil"
                  fullWidth
                >
                  {maritalStatus.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid item xs={16} sm={8}>
            <Controller
              name="education_level"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="education_level"
                  label="Nivel educativo*"
                  fullWidth
                  error={error?.type === 'required'}
                  helperText={error?.type === 'required' ? TEXT_ERROR : ''}
                >
                  {educationalLevels.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="job_position"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="job_position"
                  label="Puesto de trabajo"
                  fullWidth
                >
                  {jobs.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid item xs={16} sm={8}>
            <Controller
              name="department"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="department"
                  label="Departamento"
                  fullWidth
                >
                  {departaments.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="job_position_type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="job_position_type"
                  label="Tipo de puesto de trabajo"
                  fullWidth
                >
                  {jobPositionsType.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={16} sm={8}>
            <Controller
              name="contract_type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="contract_type"
                  label="Tipo de contratación"
                  fullWidth
                >
                  {contractsType.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="staff_type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="staff_type"
                  label="Tipo de personal"
                  fullWidth
                >
                  {staffsType.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={16} sm={8}>
            <Controller
              name="working_hours_type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="working_hours_type"
                  label="Tipo de jornada de trabajo"
                  fullWidth
                >
                  {workingHoursType.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="shift_rotation"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="shift_rotation"
                  label="Realiza rotación de turnos*"
                  fullWidth
                  error={error?.type === 'required'}
                  helperText={error?.type === 'required' ? TEXT_ERROR : ''}
                >
                  {shiftRotation.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={16} sm={8}>
            <Controller
              name="time_in_current_position"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="time_in_current_position"
                  label="Tiempo en el puesto actual*"
                  fullWidth
                  error={error?.type === 'required'}
                  helperText={error?.type === 'required' ? TEXT_ERROR : ''}
                >
                  {timeRanges.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={8}>
            <Controller
              name="time_in_company"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="time_in_company"
                  label="Tiempo en la empresa*"
                  fullWidth
                  error={error?.type === 'required'}
                  helperText={error?.type === 'required' ? TEXT_ERROR : ''}
                >
                  {timeRanges.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={16} sm={8}>
            <Controller
              name="work_experience"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  id="work_experience"
                  label="Años de experiencia*"
                  fullWidth
                >
                  {workExperience.map((el) => (
                    <MenuItem key={el.id} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '30px' }}>
          <Button variant="contained" size="large" fullWidth type="submit">
            Continuar
          </Button>
        </Box>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          No se encontró ningún colaborador
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Employee;
