import styled from 'styled-components';

export const Title = styled.p`
  font-size: 34px;
  color: #525252;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: center;
`;

export const Content = styled.div`
  .MuiLinearProgress-root {
    height: 16px;
    border-radius: 19px;
  }
  .MuiLinearProgress-bar {
    border-radius: 11px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  color: #555;
  margin-top: 40px;
  text-align: center;
`;
