import React, { useContext, useEffect, useState } from 'react';
import Employee from './Employee';
import Guide1 from './Guide1';
import Guide3 from './Guide3';
import Guide2 from './Guide2';
import Context from '../../brain/context';
import LinearProgress from '@mui/material/LinearProgress';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Title, Content, EmployeeInfo, Info } from './styles';
import axios from 'axios';
import InfoSaveForm from './infoSaveForm';

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const MainForm = () => {
  const {
    stepsForm,
    setStepsForm,
    sessionCode,
    loadingForms,
    setLoadingForms,
    setStatusQuestionnaire,
  } = useContext(Context);
  const [progress, setProgress] = useState(0);
  const [employee, setEmployee] = useState({});
  const [guides, setGuides] = useState([]);

  const [openEndSession, setOpenEndSession] = useState(false);

  const handleCloseDialogSession = () => {
    setOpenEndSession(false);
  };
  const [openSnackbarSaveForm, setOpenSnackbarSaveForm] = useState({
    type: 'success',
    open: false,
  });
  useEffect(() => {
    if (guides.length > 0) {
      if (stepsForm === 1) {
        setProgress(10);
        setLoadingForms(false);
      } else if (stepsForm === 2) {
        if (guides.find((el) => el === 'I')) {
          setProgress(25);
          setLoadingForms(false);
        } else {
          setStepsForm(3);
        }
      } else if (stepsForm === 3) {
        if (guides.find((el) => el === 'II')) {
          setProgress(25);
          setLoadingForms(false);
        } else {
          setStepsForm(4);
        }
      } else if (stepsForm === 4) {
        if (guides.find((el) => el === 'III')) {
          setProgress(75);
          setLoadingForms(false);
        } else {
          localStorage.removeItem('statusForm');
          localStorage.removeItem('stepForm');
          localStorage.removeItem('codeEmployee');
          localStorage.removeItem('employeeValues');
          localStorage.removeItem('guide1Values');
          localStorage.removeItem('guide3Values');
          localStorage.removeItem('statusForm');
          setLoadingForms(false);
          setStatusQuestionnaire('end');
        }
      }
    }
  }, [stepsForm, guides]);

  const getGuides = async () => {
    try {
      const res = await axios.get(
        `/api/v1/which_guides?session_code=${sessionCode}`
      );
      setGuides(res.data.guides);
    } catch {}
  };
  useEffect(() => {
    getGuides();
  }, []);

  const getTitle = () => {
    if (stepsForm === 1) {
      return 'Identifícate como colaborador';
    } else if (stepsForm === 2) {
      return 'Cuestionario para identificar a los trabajadores que fueron sujetos a acontecimientos traumáticos severos';
    } else if (stepsForm === 3) {
      return 'Identificación y análisis de los factores de riesgo psicosocial';
    } else if (stepsForm === 4) {
      return 'Identificación y análisis de los factores de riesgo psicosocial y evaluación del entorno organizacional en los centros de trabajo';
    }
  };

  useEffect(() => {
    const valueStg = localStorage.getItem('employeeValues');
    let value = {};
    if (valueStg) {
      value = JSON.parse(valueStg);
      setEmployee(value);
    }
  }, []);

  const handleEndSessionDialogSession = () => {
    localStorage.removeItem('statusForm');
    localStorage.removeItem('stepForm');
    localStorage.removeItem('codeEmployee');
    localStorage.removeItem('employeeValues');
    localStorage.removeItem('guide1Values');
    localStorage.removeItem('guide2Values');
    localStorage.removeItem('guide3Values');
    localStorage.removeItem('statusForm');
    setOpenSnackbarSaveForm({
      type: 'success',
      open: true,
    });

    setStatusQuestionnaire('');
  };

  return (
    <Content>
      {employee.name && (
        <Info>
          <EmployeeInfo>
            Respondiendo encuestas como:{' '}
            <b>
              {employee.name} {employee.last_name}
            </b>
          </EmployeeInfo>
          <Fab
            size="small"
            color="secondary"
            aria-label="end"
            onClick={() => setOpenEndSession(true)}
          >
            <PowerSettingsNewIcon />
          </Fab>
        </Info>
      )}
      <Title>{getTitle()}</Title>
      <Box sx={{ width: '100%' }}>
        <LinearProgressWithLabel value={progress} />
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {!loadingForms && (
        <>
          {stepsForm === 1 && (
            <Employee
              openSnackbarSaveForm={openSnackbarSaveForm}
              setOpenSnackbarSaveForm={setOpenSnackbarSaveForm}
            />
          )}
          {stepsForm === 2 && (
            <Guide1
              openSnackbarSaveForm={openSnackbarSaveForm}
              setOpenSnackbarSaveForm={setOpenSnackbarSaveForm}
            />
          )}
          {stepsForm === 3 && (
            <Guide2
              openSnackbarSaveForm={openSnackbarSaveForm}
              setOpenSnackbarSaveForm={setOpenSnackbarSaveForm}
            />
          )}
          {stepsForm === 4 && (
            <Guide3
              openSnackbarSaveForm={openSnackbarSaveForm}
              setOpenSnackbarSaveForm={setOpenSnackbarSaveForm}
            />
          )}
        </>
      )}
      {loadingForms && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress value={loadingForms ? 1 : 0} />
        </Box>
      )}
      <InfoSaveForm
        open={openSnackbarSaveForm}
        setOpen={setOpenSnackbarSaveForm}
      />

      <Dialog
        open={openEndSession}
        onClose={handleCloseDialogSession}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Está seguro que desea restablecer la sesión?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Todo el progreso incluyendo sus datos personales se eliminarán
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogSession}>Cancelar</Button>
          <Button onClick={handleEndSessionDialogSession} autoFocus>
            Restablecer sesión
          </Button>
        </DialogActions>
      </Dialog>
    </Content>
  );
};

export default MainForm;
