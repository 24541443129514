import React, { useState, useEffect, useContext } from 'react';
import {
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  Box,
  Button,
  FormHelperText,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { getTitle } from './getTitle';
import Context from '../../../brain/context';
import { initialValues } from './dataStorage';
import { useForm, Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const TEXT_ERROR = 'Campo requerido';

const Guide3 = ({ setOpenSnackbarSaveForm }) => {
  const {
    codeEmployeeCreated,
    sessionCode,
    setStatusQuestionnaire,
    setCodeEmployeeCreated,
  } = useContext(Context);

  const [workService, setWorkService] = useState(false);
  const [workBoss, setWorkBoss] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loaderData, setLoaderData] = useState(false);
  const [data, setData] = useState([]);

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      ...initialValues,
    },
  });

  const getDataForm = async () => {
    setLoaderData(true);
    try {
      const res = await axios.get(
        '/api/v1/g_three_questionnaire/g_three_items'
      );
      setData(res.data);
    } catch {
      setLoaderData(false);
    } finally {
      setLoaderData(false);
    }
  };

  useEffect(() => {
    getDataForm();
  }, []);

  const onError = (errors, e) => {
    setOpenSnackbar(true);
  };

  const onChangeWorkService = (value) => {
    setWorkService(value === 'true' ? true : false);
  };
  const onChangeWorkBoss = (value) => {
    setWorkBoss(value === 'true' ? true : false);
  };

  const saveForm = async (values) => {
    try {
      await axios.post('/api/v1/g_three_questionnaire', {
        employee_data: {
          session_code: sessionCode,
          employee_code: codeEmployeeCreated,
        },
        responses: {
          ...values,
        },
      });
      localStorage.removeItem('statusForm');
      localStorage.removeItem('stepForm');
      localStorage.removeItem('codeEmployee');
      localStorage.removeItem('employeeValues');
      localStorage.removeItem('guide1Values');
      localStorage.removeItem('guide2Values');
      localStorage.removeItem('guide3Values');
      localStorage.removeItem('statusForm');
      setOpenSnackbarSaveForm({
        type: 'success',
        open: true,
      });

      setStatusQuestionnaire('end');

      // setStepsForm(3);
      // localStorage.setItem('stepForm', '3');
    } catch {
      setOpenSnackbarSaveForm({
        type: 'error',
        open: true,
      });
    } finally {
      setButtonLoading(false);
    }
  };

  const getEmployee = async (employee_number) => {
    try {
      // const res = await axios.post(
      //   `/api/v1/employees/search?employee_number=${employee_number}&session_code=${sessionCode}`
      // );
      // setCodeEmployeeCreated(res.data.employee.code);
      // localStorage.setItem('codeEmployee', res.data.employee.code);
      let codeEmployee = localStorage.getItem('codeEmployee');
      setCodeEmployeeCreated(codeEmployee);
    } catch {
      resetForm();
    }
  };

  const onSubmit = (data) => {
    setButtonLoading(true);
    let values = data;
    if (!workService) {
      values = {
        ...values,
        response_65: '',
        response_66: '',
        response_67: '',
        response_68: '',
      };
    }

    if (!workBoss) {
      values = {
        ...values,
        response_69: '',
        response_70: '',
        response_71: '',
        response_72: '',
      };
    }
    saveForm(values);
  };

  const resetForm = () => {
    localStorage.removeItem('statusForm');
    localStorage.removeItem('stepForm');
    localStorage.removeItem('codeEmployee');
    localStorage.removeItem('employeeValues');
    localStorage.removeItem('guide1Values');
    localStorage.removeItem('guide2Values');
    localStorage.removeItem('guide3Values');
    location.reload();
  };

  useEffect(() => {
    let employeeValuesStg = localStorage.getItem('employeeValues');
    if (employeeValuesStg) {
      employeeValuesStg = JSON.parse(employeeValuesStg);
      getEmployee(employeeValuesStg.employee_number);
    } else {
      resetForm();
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      localStorage.setItem('guide3Values', JSON.stringify(value))
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const valueStg = localStorage.getItem('guide3Values');
    let value = {};
    if (valueStg) {
      value = JSON.parse(valueStg);
      reset(value);
    }
  }, []);

  const FormsHelper = (el) => {
    return (
      <div key={el.order}>
        {getTitle(el.order)}
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Controller
          name={`response_${el.order}`}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              error={error?.type === 'required'}
              name={`response_${el.order}`}
            >
              <FormLabel>
                {el.order}.- {el.name}
              </FormLabel>
              <RadioGroup
                {...field}
                row
                aria-labelledby={field.name}
                name={`response_${el.order}`}
              >
                <FormControlLabel
                  value={el.value_always}
                  control={<Radio />}
                  label="Siempre"
                />
                <FormControlLabel
                  value={el.value_almost_always}
                  control={<Radio />}
                  label="Casi siempre"
                />
                <FormControlLabel
                  value={el.value_sometimes}
                  control={<Radio />}
                  label="Algunas veces"
                />
                <FormControlLabel
                  value={el.value_rarely}
                  control={<Radio />}
                  label="Casi nunca"
                />
                <FormControlLabel
                  value={el.value_never}
                  control={<Radio />}
                  label="Nunca"
                />
              </RadioGroup>
              <FormHelperText>
                {error?.type === 'required' ? TEXT_ERROR : ''}
              </FormHelperText>
            </FormControl>
          )}
        />
        {el.order === 64 && (
          <>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <FormControl>
              <FormLabel>
                En mi trabajo debo brindar servicio a clientes o usuarios
              </FormLabel>
              <RadioGroup
                row
                onChange={(f, el) => onChangeWorkService(el)}
                defaultValue={false}
              >
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <FormControl variant="standard">
              <FormLabel>Soy jefe de otros trabajadores</FormLabel>
              <RadioGroup
                row
                onChange={(f, el) => onChangeWorkBoss(el)}
                defaultValue={false}
              >
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </>
        )}
      </div>
    );
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div>
      {!loaderData && (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {data.map((el) => {
            if (
              el.order === 65 ||
              el.order === 66 ||
              el.order === 67 ||
              el.order === 68
            ) {
              if (workService) {
                return FormsHelper(el);
              }
            } else if (
              el.order === 69 ||
              el.order === 70 ||
              el.order === 71 ||
              el.order === 72
            ) {
              if (workBoss) {
                return FormsHelper(el);
              }
            } else {
              return FormsHelper(el);
            }
          })}
          <Box sx={{ marginTop: '30px' }}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              loading={buttonLoading}
              loadingPosition="start"
              type="submit"
              startIcon={<SaveIcon />}
            >
              Continuar
            </LoadingButton>
          </Box>
        </form>
      )}
      {loaderData && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress value={loaderData ? 1 : 0} />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Falta llenar un campo en el formulario
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Guide3;
