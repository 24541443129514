import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Content = styled.div``;

export const Title = styled.p`
  font-size: 34px;
  color: #525252;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: center;
`;

export const Description = styled.p`
  font-size: 20px;
  color: #555;
  margin-bottom: 40px;
  text-align: center;
`;

export const ButtonMui = styled(Button)`
  width: 100%;
`;