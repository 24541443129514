import React from 'react';
import { Divider } from '@mui/material';
export const getTitle = (order) => {
  if (order === 1) {
    return (
      <h3>
        Para responder las preguntas siguientes considere las condiciones
        ambientales de su centro de trabajo.
      </h3>
    );
  } else if (order === 6) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Para responder a las preguntas siguientes piense en la cantidad y
          ritmo de trabajo que tiene.
        </h3>
      </>
    );
  } else if (order === 9) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con el esfuerzo mental que
          le exige su trabajo.
        </h3>
      </>
    );
  } else if (order === 13) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con las actividades que
          realiza en su trabajo y las responsabilidades que tiene.
        </h3>
      </>
    );
  } else if (order === 17) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con su jornada de trabajo.
        </h3>
      </>
    );
  } else if (order === 23) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con las decisiones que
          puede tomar en su trabajo.
        </h3>
      </>
    );
  } else if (order === 29) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con cualquier tipo de
          cambio que ocurra en su trabajo (considere los últimos cambios
          realizados).
        </h3>
      </>
    );
  } else if (order === 31) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con la capacitación e
          información que se le proporciona sobre su trabajo.
        </h3>
      </>
    );
  } else if (order === 37) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con el o los jefes con
          quien tiene contacto.
        </h3>
      </>
    );
  } else if (order === 42) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes se refieren a las relaciones con sus
          compañeros.
        </h3>
      </>
    );
  } else if (order === 47) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con la información que
          recibe sobre su rendimiento en el trabajo, el reconocimiento, el
          sentido de pertenencia y la estabilidad que le ofrece su trabajo.
        </h3>
      </>
    );
  } else if (order === 57) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con actos de violencia
          laboral (malos tratos, acoso, hostigamiento, acoso psicológico).
        </h3>
      </>
    );
  } else if (order === 65) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con la atención a clientes
          y usuarios.
        </h3>
      </>
    );
  } else if (order === 69) {
    return (
      <>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <h3>
          Las preguntas siguientes están relacionadas con las actitudes de las
          personas que supervisa.
        </h3>
      </>
    );
  }
};
